import React from "react"
export const Pricing10DataSource = {
  wrapper: { className: "home-page-wrapper pricing1-wrapper" },
  page: { className: "home-page pricing1" },
  OverPack: { playScale: 0.3, className: "pricing1-content-wrapper" },
  titleWrapper: {
    className: "pricing1-title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>Live Interview Pricing</p>
          </span>
        ),
        className: "pricing1-title-h1",
      },
      {
        name: "content~kd331buu0gg",
        className: "",
        children: (
          <span>
            <span>
              <p>Pricing is per recruiter &amp; in USD</p>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: "pricing1-block-wrapper",
    children: [
      {
        name: "block0",
        className: "pricing1-block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "pricing1-block-box " },
          topWrapper: { className: "pricing1-top-wrapper" },
          name: {
            className: "pricing1-name",
            children: (
              <span>
                <span>
                  <span>
                    <p>Basic</p>
                  </span>
                </span>
              </span>
            ),
          },
          money: {
            className: "pricing1-money",
            children: (
              <span>
                <span>
                  <span>
                    <p>49/mo</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: "pricing1-content",
            children: (
              <span>
                <p>One Way Interviews </p>
                <p>Custom Branding</p>
                <p>2 Jobs</p>
                <p>10 User Seats</p>
                <p>500 Candidates</p>
              </span>
            ),
          },
          line: { className: "pricing1-line" },
          buttonWrapper: {
            className: "pricing1-button-wrapper",
            children: {
              a: {
                className: "pricing1-button",
                href:
                  "https://recruiter.deephire.com/user/login?signupEmail=%20",
                children: (
                  <span>
                    <p>Start Free Now</p>
                  </span>
                ),
              },
            },
          },
        },
      },
      {
        name: "block1",
        className: "pricing1-block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "pricing1-block-box active" },
          topWrapper: { className: "pricing1-top-wrapper" },
          name: {
            className: "pricing1-name",
            children: (
              <span>
                <span>
                  <p>Recorded</p>
                </span>
              </span>
            ),
          },
          money: {
            className: "pricing1-money",
            children: (
              <span>
                <p>149/mo</p>
              </span>
            ),
          },
          content: {
            className: "pricing1-content",
            children: (
              <span>
                <p>
                  <b>
                    <b>Everything in Basic Plus</b>
                  </b>
                </p>
                <p>Enhanced Analytics</p>

                <p>10 Jobs</p>
                <p>20 User Seats</p>
                <p>5000 Candidates</p>
              </span>
            ),
          },
          line: { className: "pricing1-line" },
          buttonWrapper: {
            className: "pricing1-button-wrapper",
            children: {
              a: {
                className: "pricing1-button",
                href:
                  "https://recruiter.deephire.com/user/login?signupEmail=%20",
                children: (
                  <span>
                    <p>Start Free Now</p>
                  </span>
                ),
              },
            },
          },
        },
      },
      {
        name: "block2",
        className: "pricing1-block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "pricing1-block-box " },
          topWrapper: { className: "pricing1-top-wrapper" },
          name: {
            className: "pricing1-name",
            children: (
              <span>
                <p>Custom</p>
              </span>
            ),
          },
          money: {
            className: "pricing1-money",
            children: (
              <span>
                <p>Contact Us</p>
              </span>
            ),
          },
          content: {
            className: "pricing1-content",
            children: (
              <span>
                <p>
                  <b>
                    <b>Everything in Recorded Plus</b>
                  </b>
                </p>
                <p>Custom Interview Links</p>
                <p>Enhanced Custom Branding</p>
                <p>Custom Client Feedback Screen</p>
                <p>One Way Interviews</p>
              </span>
            ),
          },
          line: { className: "pricing1-line" },
          buttonWrapper: {
            className: "pricing1-button-wrapper",
            children: {
              a: {
                className: "pricing1-button",
                href: "#",
                children: (
                  <span>
                    <p>Contact Us</p>
                  </span>
                ),
              },
            },
          },
        },
      },
    ],
  },
}
export const Feature70DataSource = {
  wrapper: { className: "home-page-wrapper feature7-wrapper" },
  page: { className: "home-page feature7 kd336aewp9d-editor_css" },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: "feature7-title-wrapper",
    children: [
      {
        name: "title",
        className: "feature7-title-h1",
        children: (
          <span>
            <span>
              <span>
                <p>Frequently Asked Questions</p>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: "feature7-block-wrapper",
    gutter: 24,
    children: [
      {
        md: 24,
        xs: 24,
        name: "block~kd33gd50uvj",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/question-circle.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          Do people have to download a mobile app for the
                          interview?
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <span>
                    <p>
                      No! We do not require job seekers or clients to download a
                      mobile app. Anyone can join an interview from any device
                      with one click.&nbsp;
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 24,
        xs: 24,
        name: "block1",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/question-circle.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <p>Why would I use DeepHire over Zoom?</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            Zoom is a great tool for video meetings, but it was
                            not built for video interviews. DeepHire was built
                            specifically to solve your pain points in the
                            recruiting process. <a href="/zoom">See How</a>
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 24,
        xs: 24,
        name: "block~kd33gix0ws",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/question-circle.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>Do you offer a free trial?</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <p>
                                  Yes! You can start a trial{" "}
                                  <a href="https://recruiter.deephire.com/user/login?signupEmail=%20">
                                    here
                                  </a>
                                  . We are so confident that you will love the
                                  product, that if you are a recruiter &amp; you
                                  don't - we will send you an amazon gift card.
                                  Email us at support@deephire.com to ask more
                                  about this.&nbsp;
                                </p>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 24,
        xs: 24,
        name: "block~kd33gkgtvon",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/question-circle.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>What support do I get?</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content kd34dp9t6sa-editor_css",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          You get live concierge support for all of your
                          interviews. This ensures that your job seekers and
                          clients are able to successfully join your
                          interviews.&nbsp;
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
}
export const Content110DataSource = {
  OverPack: {
    className: "home-page-wrapper content11-wrapper kd33x9lion7-editor_css",
    playScale: 0.3,
    appear: false,
    always: false,
  },
  titleWrapper: {
    className: "title-wrapper kd34e2jvqy-editor_css",
    children: [
      {
        name: "content2",
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
        className: "title-content kd33zctfmr-editor_css",
      },
    ],
  },
  button: {
    className: "kd33xd1w89f-editor_css",
    children: {
      a: {
        className: "button kd33xf6615g-editor_css",
        href: "https://recruiter.deephire.com/user/login?signupEmail=%20",
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>Start Free Now</p>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    },
  },
}
